<template>
    <div class="grid justify-items-center">
        <img 
            src="../assets/images/wall-murals/text-image.jpg"
            class="rounded-lg shadow-xl mt-6 sm:mt-8 h-auto w-96"
        />
        <div class="mx-5 sm:mx-16 md:mx-20 grid grid-cols-1 sm:grid-cols-2 items-center gap-5 sm:gap-7">
            <img src="../assets/images/wall-murals/8.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/9.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/10.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/11.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/12.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/13.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/14.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/15.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/16.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/17.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/18.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/19.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/20.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/21.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/22.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/23.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/24.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/25.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/26.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/27.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/28.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/29.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/30.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/31.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/32.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/33.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/wall-murals/34.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
        </div>
    </div>
</template>